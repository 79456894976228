import React from 'react';
import { LayerEditorProps } from './common';
import { TripsLayer } from 'types';

interface Props extends LayerEditorProps<TripsLayer> {}

export const TripsEditor: React.FC<Props> = (api) => {
  // TODO: Create trips editor
  return <div> Trips layer editor coming here </div>;
};
