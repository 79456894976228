import React from 'react';
import { AnimationFields, ColorFields, DataFields, LayerEditorProps, LineFields } from './common';
import { PathLayer } from 'types';

interface Props extends LayerEditorProps<PathLayer> {}

export const PathEditor: React.FC<Props> = (api) => {
  return (
    <>
      <DataFields {...api} />
      <LineFields {...api} />
      <ColorFields {...api} />
      <AnimationFields {...api} />
    </>
  );
};
