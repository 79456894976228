import React from 'react';
import { Button, FieldSet, Form, Label, useStyles2 } from '@grafana/ui';
import { BarEditor } from './BarEditor';
import { IconEditor } from './IconEditor';
import { PathEditor } from './PathEditor';
import { EcaEditor } from './EcaEditor';
import { EventEditor } from './EventEditor';
import { TripsEditor } from './TripsEditor';
import { Layer } from 'types';
import { GetFieldInput } from 'components/GetField';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

interface Props {
  value?: Layer;
  onChange: (value?: Layer) => void;
  onClose: () => void;
}

export const LayerEditor: React.FC<Props> = ({ value, onChange, onClose }) => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.wrapper}>
      <Form<Layer>
        defaultValues={value}
        onSubmit={(value: any) => {
          onChange(value as Layer);
          onClose();
        }}
      >
        {(api) => {
          const { register, watch } = api;
          const type = watch('type');
          return (
            <>
              <FieldSet label="Edit layer">
                <GetFieldInput label="Name" description="A descriptive name of the layer" register={register('name')} />
                {/* 
              TODO: Reinsert InputControl
              <Field label="Type" description="Set the type of layer">
                <InputControl
                  // @ts-ignore
                  as={Select}
                  options={layerTypes}
                  name="type"
                  control={control}
                />
              </Field> */}
              </FieldSet>
              {type?.value === 'ECA' && <EcaEditor {...api} />}
              {type?.value === 'Event' && <EventEditor {...api} />}
              {type?.value === 'Icon' && <IconEditor {...api} />}
              {type?.value === 'Other' && <Label>Other editor</Label>}
              {type?.value === 'Path' && <PathEditor {...api} />}
              {type?.value === 'Trips' && <TripsEditor {...api} />}
              {type?.value === 'Weather' && <Label>Weather editor</Label>}
              {type?.value === 'Bar' && <BarEditor {...api} />}
              <Button type="submit">Save and close</Button>
            </>
          );
        }}
      </Form>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      max-height: 75vh;
      overflowy: auto;
      margin: auto;
    `,
  };
};
