import React from 'react';
import { FieldSet } from '@grafana/ui';
import { GeoJsonFields, LayerEditorProps } from './common';
import { EcaLayer } from 'types';
import { GetFieldInput } from 'components/GetField';

interface Props extends LayerEditorProps<EcaLayer> {}

export const EcaEditor: React.FC<Props> = (api) => {
  const { register } = api;
  return (
    <>
      <FieldSet label="Data settings">
        <GetFieldInput label="Query name" description="Query to use for this layer" register={register('queryId')} />
        <GetFieldInput label="Data field" description="The query field to use" register={register('field')} />
      </FieldSet>
      <GeoJsonFields {...api} />
    </>
  );
};
