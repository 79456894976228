// @ts-ignore
import { TripsLayer as Layer } from '@deck.gl/geo-layers';
import tinycolor from 'tinycolor2';
import { TripsLayer } from 'types';

export function getTripsLayer(options: TripsLayer, data: any, currentTime: number) {
  return new Layer({
    id: options.name,
    data,
    parameters: {
      depthTest: false,
    },
    widthScale: 20,
    widthMinPixels: 5,
    visible: true,
    rounded: true,
    currentTime,
    trailLength: options.trailLength,
    getTimestamps: (d: any) => d['time'],
    getPath: (d: any) => [0, 1, 2, 3],
    getWidth: (d: any) => d.__size || 5,
    getColor: (d: any) => {
      const tColor = tinycolor(d.__color || d.baseColor || [0, 33, 66, 255]).toRgb();
      return [tColor.r, tColor.g, tColor.b, tColor.a * 255];
    },
  });
}
