import React, { useState, useRef, useLayoutEffect, useMemo, useCallback, useEffect } from 'react';

import { Subscription } from 'rxjs';
import {
  classicColors,
  DashboardCursorSync,
  DataHoverClearEvent,
  DataHoverEvent,
  DataHoverPayload,
  GrafanaTheme,
  KeyValue,
  LegacyGraphHoverClearEvent,
  LegacyGraphHoverEvent,
  LoadingState,
  PanelProps,
} from '@grafana/data';
import {
  useStyles,
  useTheme2,
  usePanelContext,
  Button,
  VerticalGroup,
  IconButton,
  Checkbox,
  ClickOutsideWrapper,
} from '@grafana/ui';
import { getLocationSrv } from '@grafana/runtime';
import { css, cx } from '@emotion/css';
import { Deck as DeckGL, WebMercatorViewport, FlyToInterpolator, MapView, PickingInfo } from '@deck.gl/core/typed';
// @ts-ignore
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import {
  getBarLayer,
  getIconLayer,
  getPathsLayer,
  getEcaLayer,
  getEventsLayer,
  getTripsLayer,
} from 'components/Layers';
import { DeckComponentOptions, ViewState } from 'types';
import { useDebouncedCallback } from 'use-debounce';
import { clamp } from 'lodash';
import { HoverTooltip } from 'components/Tooltip/HoverTooltip';

// TODO: Move keys to env variables
const mapStyleKeys = {
  light: '2898478a-c52e-45cd-a436-9415d9c39052',
  dark: 'e669cf8d-e345-4d97-aad6-257c8f0afdea',
};

const isNumber = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

const INITIAL_VIEW_STATE: ViewState = {
  longitude: 0,
  latitude: 0,
  zoom: 1.5,
  pitch: 0, // 20
  bearing: 0,
};

const getInitialViewState = (bounds: [[number, number], [number, number]], height: number, width: number) => {
  const viewPort = new WebMercatorViewport({
    height,
    width,
  });
  const { latitude, longitude, zoom } = viewPort.fitBounds(bounds, { padding: 30 });
  return { ...INITIAL_VIEW_STATE, latitude, longitude, zoom };
};

const TRANSITION_DURATION = 1.2 * 1000;

const getInitialBounds: () => [number, number, number, number] = () => [-180 * 3, -90 * 3, 180 * 3, 90 * 3];

const getStyles = (width: number, height: number) => (theme: GrafanaTheme) => {
  return {
    toolboxWrapper: css`
      position: absolute;
      bottom: 0px;
      z-index: 10;
      padding: 0.5em 0.5em 0em 0em;
      background: ${theme.colors.panelBg};
      border-radius: 0px 1em 0px 0px;
    `,
    toolboxToggle: css`
      display: flex;
      justify-content: end;
      width: 100%;
      margin-top: 2px;
      min-width: 1.8em;
      min-height: 1.4em;
    `,
    wrapper: css`
      position: relative;
      height: ${height}px;
      width: ${width}px;
      bottom: ${theme.panelPadding}px;
      right: ${theme.panelPadding}px;
      canvas[id^='deckgl-overlay'] {
        z-index: 1;
      }
    `,
    base: css`
      border-radius: ${theme.border.radius}px;
      margin: ${theme.panelPadding}px;
      overflow: hidden;
    `,
  };
};

interface Props extends PanelProps<DeckComponentOptions> {}

function getQuery(options: DeckComponentOptions, viewState: ViewState) {
  const res: Record<string, any> = {};
  const { zoomlevel, latitude, longitude, pitch, bearing, bbox } = options.updateVariables;
  if (zoomlevel) {
    res['var-zoomlevel'] = viewState.zoom.toString();
  }
  if (longitude) {
    res['var-longitude'] = viewState.longitude.toString();
  }
  if (latitude) {
    res['var-latitude'] = viewState.latitude.toString();
  }
  if (pitch) {
    res['var-pitch'] = viewState.pitch.toString();
  }
  if (bearing) {
    res['var-bearing'] = viewState.bearing.toString();
  }

  if (bbox) {
    const viewport = new WebMercatorViewport(viewState);
    const nw = viewport.unproject([0, 0]);
    const se = viewport.unproject([viewport.width, viewport.height]);
    res['var-nw'] = [clamp(nw[0], -180, 180), clamp(nw[1], -90, 90)];
    res['var-se'] = [clamp(se[0], -180, 180), clamp(se[1], -90, 90)];
  }
  return res;
}

interface HoverInfo extends PickingInfo {
  time?: number;
  dataIndex?: number;
}

const Deck: React.FC<Props> = (props) => {
  const { width, height, options, data, id } = props;
  const { fitToBoundsLatitude, fitToBoundsLongitude } = options;
  const ctx = usePanelContext();
  const { sync, eventBus } = ctx;

  const theme = useTheme2();

  const styles = useStyles(getStyles(width, height));
  const deckGlRef = useRef<DeckGL>();
  const map = useRef<maplibregl.Map>();
  const [viewState, setViewState] = useState<ViewState>(INITIAL_VIEW_STATE);
  const [hoverInfo, setHoverInfo] = useState<HoverInfo>();
  const [layers, setLayers] = useState<any[]>([]);
  const [bounds, setBounds] = useState<[number, number, number, number]>();
  const [hoverTime, setHoverTime] = useState<number | undefined>();
  const [showEvents, setShowEvents] = useState<boolean>(true || options.showEvents || false);
  const [sharedCrosshair, setSharedCrosshair] = useState<boolean>(!options.disableSharedTooltip);
  const [showToolbar, setShowToolbar] = useState<boolean>(false);

  useEffect(() => {
    if (sharedCrosshair) {
      if (hoverInfo?.time) {
        // Build the payload and publish the event
        const payload: DataHoverPayload = {
          point: {
            time: hoverInfo?.time,
          },
        };
        eventBus.publish(new DataHoverEvent(payload));
      } else {
        eventBus.publish(new DataHoverClearEvent());
      }
    } else {
      setHoverTime(hoverInfo?.time);
    }
  }, [hoverInfo, eventBus, sharedCrosshair]);

  const handleHoverEvent = useCallback(
    (e: DataHoverEvent | DataHoverClearEvent) => {
      if (!sharedCrosshair) {
        return;
      }
      if (isNumber(e?.payload?.point?.time)) {
        setHoverTime((t) => (t !== e.payload.point.time ? e.payload.point.time : t));
      } else {
        setHoverTime(undefined);
      }
    },
    [sharedCrosshair]
  );

  useEffect(() => {
    const subscription = new Subscription();
    if (sync && sync() !== DashboardCursorSync.Off) {
      subscription.add(eventBus.subscribe(DataHoverEvent, handleHoverEvent));
      subscription.add(eventBus.subscribe(DataHoverClearEvent, handleHoverEvent));
      subscription.add(eventBus.subscribe(LegacyGraphHoverEvent, handleHoverEvent));
      subscription.add(eventBus.subscribe(LegacyGraphHoverClearEvent, handleHoverEvent));
    }
    return () => {
      subscription.unsubscribe();
    };
  }, [eventBus, sync, handleHoverEvent]);

  const updateVariables = useDebouncedCallback((viewState: ViewState) => {
    getLocationSrv().update({
      partial: true,
      query: getQuery(options, viewState),
      replace: true,
    });
  }, 500);

  useLayoutEffect(() => {
    updateVariables(viewState);
  }, [updateVariables, viewState]);

  const mergedData = useMemo(async () => {
    if (data.state === 'Done') {
      const _bounds = getInitialBounds();
      return data.series.map((serie) => {
        let items = new Array(serie.length);
        serie.fields.forEach((field, idx) => {
          items = field.values.toArray().map((value, idx) => {
            const latitudeField = fitToBoundsLatitude || 'position_x';
            const longitudeField = fitToBoundsLongitude || 'position_y';
            if (field.name === longitudeField && (value === 0 || value)) {
              if (value > _bounds[1]) {
                _bounds[1] = value;
              }
              if (value < _bounds[3]) {
                _bounds[3] = value;
              }
            }
            if (field.name === latitudeField && (value === 0 || value)) {
              if (value > _bounds[0]) {
                _bounds[0] = value;
              }
              if (value < _bounds[2]) {
                _bounds[2] = value;
              }
            }
            const oldItem: KeyValue = items[idx] || {};
            oldItem[field.name] = value;
            if (!oldItem['baseColor']) {
              oldItem['baseColor'] = classicColors[idx];
            }
            return oldItem;
          });
        });
        setBounds((e) => {
          const initBounds = getInitialBounds();
          if (
            !e?.length ||
            (e?.reduce((a, b, idx) => a || b !== _bounds[idx], false) &&
              initBounds?.reduce((a, b, idx) => a || b !== _bounds[idx], false))
          ) {
            return _bounds;
          }
          return e;
        });
        return items;
      });
    }
    return [];
  }, [data, fitToBoundsLatitude, fitToBoundsLongitude]);

  useEffect(() => {
    const getLayers = async () => {
      if (data.state !== LoadingState.Error && data.state !== LoadingState.NotStarted) {
        const _data = await mergedData;
        const res = options.layers
          .filter((e) => !!e)
          .flatMap((layer) => {
            if (layer.type?.value === 'Bar') {
              return [getBarLayer(layer, data.series, setHoverInfo)];
            }
            if (layer.type?.value === 'Event' && showEvents) {
              return [getEventsLayer(layer, data, setHoverInfo)];
            }
            if (layer.type?.value === 'ECA') {
              return [getEcaLayer(layer, data, setHoverInfo)];
            }
            if (layer.type?.value === 'Icon') {
              return [getIconLayer(layer, data.series, setHoverInfo)];
            }
            if (layer.type?.value === 'Path') {
              return getPathsLayer(layer, data.series, setHoverInfo, hoverTime, data.request?.intervalMs, theme.isDark);
            }
            if (layer.type?.value === 'Trips') {
              return [getTripsLayer(layer, _data[0], 1000)];
            } else {
              return [];
            }
          })
          .filter((e) => e !== undefined)
          .reverse();
        setLayers(res);
      }
    };
    getLayers();
  }, [hoverTime, mergedData, data, options.layers, theme, showEvents]);

  useEffect(() => {
    if (map.current) {
      return;
    }

    map.current = new maplibregl.Map({
      container: `map-${id}`,
      style: `https://api.maptiler.com/maps/${
        theme.isLight ? mapStyleKeys.light : mapStyleKeys.dark
      }/style.json?key=RtpzrAWmnPkmA6SHqzr5`,
      interactive: false,
      renderWorldCopies: true,
      center: [viewState.longitude, viewState.latitude],
      ...viewState,
    });
  });

  useEffect(() => {
    if (deckGlRef.current) {
      return;
    }

    deckGlRef.current = new DeckGL({
      canvas: `deckgl-overlay-${id}`,
      initialViewState: INITIAL_VIEW_STATE,
      controller: true,
      pickingRadius: 10,
      views: new MapView({
        repeat: true,
        // nearZMultiplier: 0.1,
        // farZMultiplier: 1.01,
        // orthographic: false,
      }),
      getCursor: () => 'default',
      onViewStateChange: ({ viewState }: any) => setViewState(viewState),
    });
  });

  useEffect(() => {
    if (deckGlRef.current && layers.length) {
      deckGlRef.current.setProps({
        layers: layers,
      });
    }
  }, [layers]);

  useEffect(() => {
    if (map.current) {
      map.current.resize();
    }
  }, [height, width, styles]);

  const resetViewState = useCallback(() => {
    if (bounds) {
      const viewState = getInitialViewState(
        [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]],
        ],
        height,
        width
      );
      viewState.zoom = Math.min(viewState.zoom, options.fitToBoundsMaxZoom || 15);
      setViewState(viewState);
      if (deckGlRef.current) {
        deckGlRef.current.setProps({
          initialViewState: {
            ...viewState,
            transitionDuration: TRANSITION_DURATION,
            transitionInterpolator: new FlyToInterpolator(),
          },
        });
      }
    }
  }, [bounds, height, width, options.fitToBoundsMaxZoom]);

  useEffect(() => {
    resetViewState();
  }, [resetViewState]);

  useEffect(() => {
    if (map.current) {
      map.current.jumpTo({ ...viewState, center: [viewState.longitude, viewState.latitude] });
    }
  }, [viewState]);

  const Hover = React.useMemo(
    () =>
      hoverInfo?.object && (
        <HoverTooltip
          {...hoverInfo}
          type={(hoverInfo.layer?.props as any).type || 'default'}
          height={height}
          width={width}
        />
      ),
    [hoverInfo, height, width]
  );

  return (
    <div className={cx(styles.base, styles.wrapper)}>
      <canvas id={`deckgl-overlay-${id}`}></canvas>
      <div style={{ height, width }} id={`map-${id}`} />
      <ClickOutsideWrapper onClick={() => setShowToolbar(false)}>
        <div className={cx(styles.toolboxWrapper)}>
          <div className={cx(styles.toolboxToggle)}>
            <IconButton
              name={showToolbar ? 'angle-left' : 'angle-right'}
              onClick={(e) => {
                setShowToolbar((e) => !e);
                e.stopPropagation();
              }}
              size="xs"
            />
          </div>
          {showToolbar && (
            <div style={{ margin: '-0.8em 0.8em 0.8em 0.8em' }}>
              <VerticalGroup spacing="xs">
                <Button variant="secondary" size="sm" onClick={resetViewState}>
                  Reset zoom
                </Button>
                <Checkbox value={showEvents} onChange={() => setShowEvents((e) => !e)} label="Show events" />
                <Checkbox value={sharedCrosshair} onChange={() => setSharedCrosshair((e) => !e)} label="Sync graphs" />
              </VerticalGroup>
            </div>
          )}
        </div>
      </ClickOutsideWrapper>
      {Hover}
    </div>
  );
};

export default Deck;
