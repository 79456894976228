import React from 'react';

import { FieldSet } from '@grafana/ui';
import { AnimationFields, DataFields, LayerEditorProps } from './common';
import { IconLayer } from 'types';
import { GetFieldInput, GetFieldSwitch, GetFieldTextArea } from 'components/GetField';

interface Props extends LayerEditorProps<IconLayer> {}

export const IconEditor: React.FC<Props> = (api) => {
  const { register, watch, errors } = api;
  const pickable = watch('pickable');
  return (
    <>
      <DataFields {...api} />
      <FieldSet label="Visual settings">
        <GetFieldInput
          label="Icon size"
          description="Icon size in px"
          register={register('iconSize', {
            min: 1,
            valueAsNumber: true,
            validate: (e) => e === undefined || e >= 1,
          })}
          type={'number'}
          invalid={errors.iconSize !== undefined}
        />
        <GetFieldTextArea label="Custom Icon" description="svg of a custom icon" register={register('icon')} />
        <GetFieldSwitch
          label="Show angle"
          description="Icon should rotate to indicate direction"
          register={register('showAngle')}
        />
        <GetFieldSwitch
          label="Flat on map"
          description="Icon should be flat on the surface, not on the screen"
          register={register('showBillboard')}
        />
        <GetFieldSwitch
          label="Pickable Icons"
          description="Icon should respond to onHover"
          register={register('pickable')}
        />
        {pickable && (
          <GetFieldTextArea
            label="Hover content"
            description="Text to display on hover"
            register={register('hoverContent')}
          />
        )}
      </FieldSet>
      <AnimationFields {...api} />
    </>
  );
};
