// @ts-ignore
import { GeoJsonLayer as Layer } from '@deck.gl/layers';
import { PanelData } from '@grafana/data';
import tinycolor from 'tinycolor2';
import { EcaLayer } from 'types';
import { getSeries } from 'utils';

export function getEcaLayer(options: EcaLayer, data: PanelData, onHover: (object: any) => void) {
  const tColor = tinycolor('green').toRgb();
  const series = getSeries(data.series, options.queryId);
  if (options.field) {
    const fields = series.fields.filter((e) => e.name === options.field);
    const geoJson = Array(series.length);
    for (let findex = 0; findex < fields.length; findex++) {
      const field = fields[findex];
      for (let index = 0; index < series.length; index++) {
        geoJson[index] = JSON.parse(field.values.get(index));
      }
    }
    return new Layer({
      id: options.name,
      data: geoJson,
      stroked: options.showStroke,
      pickable: true,
      filled: options.showFill,
      lineWidthMinPixels: 2,
      getFillColor: (d: any) => {
        return [tColor.r, tColor.g, tColor.b, tColor.a * 255 * 0.3];
      },
      getLineColor: [tColor.r, tColor.g, tColor.b, tColor.a * 255],
      getLineWidth: 100,
      onHover,
    });
  }
}
