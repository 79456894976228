import { Mapping } from 'types';

export const iconMapping: Mapping = {
  vessel: {
    x: 0,
    y: 0,
    width: 35,
    height: 63,
    anchorY: 31.5,
    anchorX: 17.5,
    mask: true,
  },
  event: {
    x: 0,
    y: 0,
    width: 210,
    height: 320,
    anchorY: 320,
    anchorX: 105,
    mask: true,
  }
};
