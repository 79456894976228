import React, { useState } from 'react';
import { LayerEditor as ItemEditor } from './LayerEditor';

import { IconButton, Icon, Select, Modal, VerticalGroup, Card, Tag, stylesFactory } from '@grafana/ui';
import { css } from '@emotion/css';
import { animated, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { clamp } from 'lodash';
import { LayerType, Layer } from 'types';
import { PanelOptionsEditorProps, StandardEditorProps } from '@grafana/data';

interface SelectableLayerType {
  value: LayerType;
  label?: string;
  description?: string;
}

export const layerTypes: SelectableLayerType[] = [
  {
    value: 'Bar',
    label: 'Bar layer',
    description: 'This adds bars to the panel. A bar is shown for each data point',
  },
  {
    value: 'Icon',
    label: 'Icon layer',
    description: 'This adds icons to the panel. Icons are shown for each data point',
  },
  {
    value: 'Path',
    label: 'Paths layer',
    description: 'This adds static paths to the panel. Paths are drawn between each data point',
  },
  {
    value: 'Trips',
    label: 'Trips layer',
    description: 'This adds an animated paths layer to the panel. It draws a fading path for each trace',
  },
  {
    value: 'Event',
    label: 'Events layer',
    description: 'This adds icons for events to the panel. Events are shown for each data point',
  },
  {
    value: 'ECA',
    label: 'ECA layer',
    description: 'This adds ECA to the panel. Areas are shown for each field',
  },
  {
    value: 'Weather',
    label: 'Weather layer',
    description: 'This adds weather data to the panel. Weather is shown for the time frame',
  },
  {
    value: 'Other',
    label: 'Custom layer',
    description: 'This adds a generic layer to the panel',
  },
];

const getStyles = stylesFactory(() => {
  return {
    content: css`
      position: relative;
    `,
    contentItem: css`
      position: absolute;
      transform-origin: 50% 50% 0px;
      touch-action: none;
      width: 100%;
    `,
  };
});

function move(array: any[], moveIndex: number, toIndex: number) {
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      // @ts-ignore
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
}

function fn(order: number[], curIndex = 0, y = 0, down?: boolean, originalIndex?: number) {
  return (index: number) =>
    down && index === originalIndex
      ? {
          y: curIndex * 100 + y,
          scale: 1.1,
          zIndex: '1',
          shadow: 15,
          immediate: (n: string) => n === 'y' || n === 'zIndex',
        }
      : { y: order.indexOf(index) * 100, scale: 1, zIndex: '0', shadow: 1, immediate: false };
}

export const LayersEditor: React.ComponentType<PanelOptionsEditorProps<Layer[] | undefined>> &
  React.ComponentType<StandardEditorProps<Layer[] | undefined, any, any>> = ({ value: items, onChange }) => {
  const styles = getStyles();
  const [edit, setEdit] = useState<number>();
  const [order, setOrder] = useState<number[]>(items?.map((_, idx) => idx) || []);

  const onValueChange = React.useCallback(
    (items?: Layer[], order?: number[]) => {
      onChange(items);
      if (order) {
        setOrder(order);
      }
    },
    [onChange]
  );

  const [springs, setSprings] = useSprings(items?.length || 0, fn(order), [items]);

  const bind = useDrag((state) => {
    const {
      args: [originalIndex],
      active,
      movement: [, y],
    } = state;
    const curIndex = order.indexOf(originalIndex);
    const curRow = clamp(Math.round((curIndex * 100 + y) / 100), 0, (items?.length || 0) - 1);
    const newOrder = move(order, curIndex, curRow);
    if (active) {
      setSprings(fn(newOrder, curIndex, y, active, originalIndex));
    }

    if (!active && items?.length) {
      onValueChange(newOrder.map((e) => items[e]));
    }
  }, {});

  const addItem = (e?: SelectableLayerType) => {
    if (e) {
      const newItems = [
        { type: e, name: e.label },
        // @ts-ignore
        ...order.map((e) => items[e]),
      ];
      const newOrder = newItems.map((_, idx) => idx);

      onValueChange(newItems, newOrder);
      setSprings(fn(newOrder));
    }
  };

  const removeItem = (e?: number) => {
    if (e !== undefined) {
      if (items?.length) {
        const newItems = order.filter((id) => id !== e).map((e) => items[e]);
        const newOrder = newItems.map((_, idx) => idx);

        onValueChange(newItems, newOrder);
        setSprings(fn(newOrder));
      }
    }
  };

  const updateValue = (idx: number, value: Layer) => {
    if (idx !== undefined && items?.length) {
      const newItems = items.map((e, i) => (idx === i ? value : e));
      const newOrder = newItems.map((_, idx) => idx);

      onValueChange(newItems, newOrder);
      setSprings(fn(newOrder));
    }
  };

  return (
    <VerticalGroup>
      <Select onChange={(e) => addItem(e as SelectableLayerType)} prefix={<Icon name="plus" />} options={layerTypes} />
      <div style={{ height: (items?.length || 0) * 100 }} className={styles.content}>
        {springs.map((props, i) => (
          <animated.div
            {...bind(i)}
            key={items?.[i].name}
            // @ts-ignore
            style={props}
            className={styles.contentItem}
          >
            <Card heading={items?.[i]?.name} style={{ width: 350 }}>
              {items?.[i]?.type ? (
                <Card.Tags>
                  <Tag name={items?.[i]?.type?.label || ''} />
                </Card.Tags>
              ) : null}
              <Card.SecondaryActions>
                <IconButton onClick={() => setEdit(i)} size="sm" name="edit" />
                <IconButton onClick={() => removeItem(i)} size="sm" name="trash-alt" />
              </Card.SecondaryActions>
            </Card>
          </animated.div>
        ))}
      </div>
      <Modal title="Edit layer" isOpen={edit !== undefined} onDismiss={() => setEdit(undefined)}>
        {edit !== undefined ? (
          <ItemEditor
            value={items?.[edit]}
            onChange={(e) => {
              if (e) {
                updateValue(edit, e);
              }
            }}
            onClose={() => setEdit(undefined)}
          />
        ) : null}
      </Modal>
    </VerticalGroup>
  );
};
