import { IconLayer as Layer } from '@deck.gl/layers/typed';
import { KeyValue, PanelData } from '@grafana/data';
import { iconMapping } from 'const';
import events from 'img/event-icon.svg';
import tinycolor from 'tinycolor2';
import { IconLayer, LayerData, LayerType } from 'types';
import { getSeries } from 'utils';

export const getEventColor = (type: string) => {
  switch (type) {
    case 'LeftBerthEvent':
      return '#332288';
    case 'ArrivedAtBerthEvent':
      return '#88CCEE';
    case 'BunkerManualEvent':
      return '#44AA99';
    case 'BunkerMeasuredEvent':
      return '#44AA99';
    case 'CargoOperationEvent':
      return '#117733';
    case 'FuelChangeEvent':
      return '#999933';
    case 'ReportEvent':
      return '#DDCC77';
    case 'SeaPassageInterruptedEvent':
      return '#CC6677';
    case 'SeaPassageRecommencedEvent':
      return '#882255';
    case 'ModeChangeEvent':
      return '#AA4499';
    case 'CorrectionEvent':
      return '#C81D13';
    case 'FuelRegistrationEvent':
      return '#BAB0AB';
    default:
      return '#DDDDDD';
  }
};

interface EventData extends LayerData {
  current: any;
}

export function getEventsLayer(options: IconLayer, data: PanelData, onHover: (object: any) => void) {
  let dataField = options.useAnnotation ? options.annotationField : options.field;
  dataField = dataField || options.field || options.annotationField;
  if (!dataField) {
    return undefined;
  } else {
    const serie = getSeries(data.series, options.queryId);

    const annotations = data.annotations;
    if (!serie || !annotations) {
      return undefined;
    } else {
      const _data: EventData[] = [];
      const metricField = serie.fields.find((f) => f.name === dataField?.metric || f.name === 'metric');
      const fieldNames = serie.fields.map((e) => e.name);
      for (let index = 0; index < serie.length; index++) {
        const current: KeyValue<any> = {};
        fieldNames.forEach((name) => (current[name] = serie.fields.find((f) => f.name === name)?.values.get(index)));
        if (current[dataField?.latitude || 'latitude'] && current[dataField?.longitude || 'longitude']) {
          _data.push({
            fields: serie.fields,
            color: getEventColor(metricField?.values.get(index)),
            current,
          });
        }
      }

      return new Layer<EventData, { type: LayerType }>({
        id: options.name,
        type: 'Event',
        data: _data,
        parameters: {
          depthTest: false,
        },
        iconAtlas: options.iconAtlas || events,
        iconMapping: iconMapping,
        sizeMinPixels: 25,
        sizeMaxPixels: 25,
        pickable: true,
        visible: true,
        billboard: true,
        getPosition: (d) => [
          d.current[dataField?.latitude || 'latitude'],
          d.current[dataField?.longitude || 'longitude'],
        ],
        getIcon: () => 'event',
        getSize: (d) => d.current['size'] || 5,
        getColor: (d) => {
          const tColor = tinycolor(d.color || '#005da8').toRgb();
          return [tColor.r, tColor.g, tColor.b, tColor.a * 255];
        },
        getAngle: (d) => d.current['bearing'] || 0,
        onHover: (d) => onHover({ ...d, time: d.object?.current?.start_time }),
        updateTriggers: {
          getPosition: _data,
        },
      });
    }
  }
}
