import { PathLayer as _PLayer } from '@deck.gl/layers/typed';
import { TripsLayer as _TLayer } from '@deck.gl/geo-layers/typed';
import { PathStyleExtension } from '@deck.gl/extensions/typed';
import { DataFrame, Field, getFieldColorModeForField, Labels, Vector } from '@grafana/data';
import tinycolor from 'tinycolor2';
import { LayerData, LayerType, PathLayer } from 'types';
import { getSeries } from 'utils';

// a function to determine distance between two points
function distance(lat1: number, lon1: number, lat2: number, lon2: number) {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

function findClosestPointIndex(point: [number, number], path: Array<[number, number]>) {
  let closestPointIndex = -1;
  let minDistance = Number.MAX_SAFE_INTEGER;
  for (let index = 0; index < path.length; index++) {
    const element = path[index];
    const d = distance(point[0], point[1], element[0], element[1]);
    if (d < minDistance) {
      minDistance = d;
      closestPointIndex = index;
    }
  }
  return closestPointIndex;
}

interface PathData extends LayerData {
  path: Array<[number, number]>;
}

export function getPathsLayer(
  options: PathLayer,
  data: DataFrame[],
  onHover: (object: any) => void,
  hoverTime?: number,
  interval?: number,
  themeIsDark?: boolean
) {
  let dataField = options.useAnnotation ? options.annotationField : options.field;
  dataField = dataField || options.field || options.annotationField;
  if (!dataField) {
    return undefined;
  } else {
    const serie = getSeries(data, options.queryId);
    const _data: PathData[] = [];
    const firstTime = serie.fields.find((e) => e.type === 'time')?.values.get(0) || 0;

    serie?.fields
      .filter((e) => e.labels !== undefined)
      .map<Labels>((e) => e.labels as unknown as Labels)
      .reduce((acc, cur) => {
        const keys = Object.keys(cur);
        if (
          !acc.some(
            (e) =>
              !keys.some((key) => {
                if (!e[key] || e[key] !== cur[key]) {
                  return true;
                }
                return false;
              })
          )
        ) {
          acc.push(cur);
        }
        return acc;
      }, [] as Labels[])
      .forEach((label: Labels, index) => {
        const fields = serie?.fields.filter((e) => e.labels?.['metric'] === label['metric'] || e.type === 'time');
        const latField = fields.find((e) => e.name === dataField?.latitude || e.name === 'latitude');
        const longField = fields.find((e) => e.name === dataField?.longitude || e.name === 'longitude');
        const path: any[] = [];
        if (latField && longField) {
          for (let index = 0; index < serie.length; index++) {
            const pos = [latField.values.get(index), longField.values.get(index)];
            if (pos[0] && pos[1]) {
              path.push(pos);
            }
          }
        }
        const field = fields.find((f) => f.name === dataField?.metric || f.name === 'metric') || fields[0];
        const mode = getFieldColorModeForField(field);
        let displayColor = field?.display?.('').color;
        if (mode.id === 'palette-classic') {
          displayColor = (mode as any).colorCache?.[index];
        }

        _data.push({
          fields: fields,
          path: path.filter((e) => e[0] && e[1]),
          color: displayColor || '#005da8',
        });
      });
    const extensions = [];
    if (options.dashed) {
      extensions.push(new PathStyleExtension({ dash: true, highPrecisionDash: true }));
    }

    return [
      new _TLayer<PathData, { type: LayerType }>({
        id: options.name + '_trips',
        type: 'Path',
        data: _data,
        currentTime: hoverTime ? hoverTime - firstTime : 0,
        getColor: (d) => {
          const tColor = tinycolor(d.color || '#005da8')
            .darken(themeIsDark ? 0 : 30)
            .brighten(themeIsDark ? 30 : 0)
            .toRgb();
          return [tColor.r, tColor.g, tColor.b, 255];
        },
        getPath: (d) => d.path,
        getWidth: () => 6,
        getTimestamps: (d) =>
          (d as PathData).fields
            .find((e) => e.type === 'time')
            ?.values.toArray()
            .map((e) => e - firstTime) || [],
        opacity: 1,
        parameters: { depthTest: false },
        trailLength: (interval || 0) * 20,
        visible: true,
        rounded: true,
        fadeTrail: true,
        widthUnits: 'pixels',
        widthScale: 1,
        widthMinPixels: 1,
      }),
      new _PLayer<PathData, { type: LayerType }>({
        id: options.name,
        type: 'Path',
        data: _data,
        widthUnits: 'pixels',
        widthScale: 1,
        widthMinPixels: 1,
        visible: true,
        jointRounded: true,
        pickable: true,
        wrapLongitude: true,
        getPath: (d) => d.path,
        getWidth: () => 3,
        getColor: (d) => {
          const tColor = tinycolor(d.color || '#005da8').toRgb();
          return [tColor.r, tColor.g, tColor.b, (options.transparency || tColor.a) * 255];
        },
        onHover: (data) => {
          if (data.coordinate?.length === 2 && data.object) {
            const i = findClosestPointIndex(data.coordinate as [number, number], data.object?.path);
            if (i !== undefined && i !== -1) {
              const timeField = data.object.fields.find((e: Field<any, Vector<number>>) => e.type === 'time');
              const time = timeField?.values.get(i);
              return onHover({ ...data, time, dataIndex: i });
            }
          }
          return onHover(data || undefined);
        },
        parameters: { depthTest: false },
        extensions,
        ...(extensions.length && { getDashArray: [10, 10] }),
      }),
    ];
  }
}
