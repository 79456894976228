import React, { forwardRef, HTMLAttributes } from 'react';
import { cx, css } from '@emotion/css';
import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import tinycolor from 'tinycolor2';

/**
 * @public
 */
export type OnTagClick = (name: string, event: React.MouseEvent<HTMLElement>) => any;

export interface Props extends Omit<HTMLAttributes<HTMLElement>, 'onClick'> {
  name: string;
  color: string;
  onClick?: OnTagClick;
}

export const Tag = forwardRef<HTMLElement, Props>(({ name, onClick, className, color, ...rest }, ref) => {
  const styles = useStyles(getTagStyles(color));

  const onTagClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(name, event);
    }
  };

  return (
    <span
      key={name}
      ref={ref}
      onClick={onTagClick}
      className={cx(styles.wrapper, className, onClick && styles.hover)}
      {...rest}
    >
      {name}
    </span>
  );
});

Tag.displayName = 'Tag';

const getTagStyles = (color: string) => (theme: GrafanaTheme) => {
  const brightness = tinycolor(color).getBrightness();
  const textColor = brightness > 125 ? theme.palette.gray33 : theme.palette.gray98;
  return {
    wrapper: css`
      font-weight: ${theme.typography.weight.semibold};
      font-size: ${theme.typography.size.sm};
      line-height: ${theme.typography.lineHeight.xs};
      vertical-align: baseline;
      background-color: ${color};
      color: ${textColor};
      white-space: nowrap;
      text-shadow: none;
      padding: 3px 6px;
      border-radius: ${theme.border.radius.md};
    `,
    hover: css`
      &:hover {
        opacity: 0.85;
        cursor: pointer;
      }
    `,
  };
};
