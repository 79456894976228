import React from 'react';
import { IconLayer } from 'types';
import { AnimationFields, DataFields, LayerEditorProps } from './common';

interface Props extends LayerEditorProps<IconLayer> {}

export const EventEditor: React.FC<Props> = (api) => {
  return (
    <>
      <DataFields {...api} />
      <AnimationFields {...api} />
    </>
  );
};
