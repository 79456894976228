import React from 'react';
import { Switch, Field, Input, TextArea } from '@grafana/ui';

interface Props {
  label: string;
  register: any;
  invalid?: boolean;
  description?: string;
  type?: string;
}

export const GetFieldInput: React.FC<Props> = ({ label, register, invalid, description }) => (
  <Field label={label} description={description} invalid={invalid}>
    <Input
      {...register}
      // @ts-ignore
      css={undefined}
    />
  </Field>
);

export const GetFieldSwitch: React.FC<Props> = ({ label, register, invalid, description }) => (
  <Field label={label} description={description} invalid={invalid}>
    <Switch
      {...register}
      // @ts-ignore
      css={undefined}
    />
  </Field>
);

export const GetFieldTextArea: React.FC<Props> = ({ label, register, invalid, description }) => (
  <Field label={label} description={description} invalid={invalid}>
    <TextArea
      {...register}
      // @ts-ignore
      css={undefined}
    />
  </Field>
);
