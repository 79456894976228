import React from 'react';
import { FieldSet, Label, FormAPI} from '@grafana/ui';
import { FieldValues } from 'react-hook-form';
import { DataLayer, GeoJsonLayer, AnimationLayer, ColorLayer, LineLayer } from 'types';
import { GetFieldInput, GetFieldSwitch } from 'components/GetField';

export interface LayerEditorProps<T extends FieldValues> extends FormAPI<T> {}

interface DataFieldsProps extends LayerEditorProps<DataLayer> {}

interface LineFieldsProps extends LayerEditorProps<LineLayer> {}

interface GeoJsonFieldsProps extends LayerEditorProps<GeoJsonLayer> {}
interface AnimationFieldsProps extends LayerEditorProps<AnimationLayer> {}
interface ColorFieldsProps extends LayerEditorProps<ColorLayer> {}

export const LineFields: React.FC<LineFieldsProps> = ({ register, errors, control, watch }) => {
  return (
    <FieldSet label="Line settings">
      <GetFieldInput
        label="Line width"
        description="The width of the line"
        register={register('width', {
          min: 0,
          valueAsNumber: true,
          validate: (e) => e === undefined || e >= 0,
        })}
        type={'number'}
        invalid={errors.width !== undefined}
      />
      <GetFieldSwitch label="Dashed" description="Draw a dashed line" register={register('dashed')} />
    </FieldSet>
  );
};

export const DataFields: React.FC<DataFieldsProps> = ({ register, errors, control, watch }) => {
  const useAnnotation = watch('useAnnotation');
  return (
    <FieldSet label="Data settings">
      <GetFieldInput label="Query name" description="Query to use for this layer" register={register('queryId')} />
      <Label description="Data field to use for this layer">Data fields</Label>
      <GetFieldSwitch
        label="Use annotation data?"
        description="This layer will ignore the sql query and use annontation data instead"
        register={register('useAnnotation')}
      />
      <div style={useAnnotation ? {} : { display: 'none' }}>
        <GetFieldInput
          label="Annotation latitude field"
          description="The annotation field to use for latitude"
          register={register('annotationField.latitude')}
        />
        <GetFieldInput
          label="Annotation longitude field"
          description="The annotation field to use for longitude"
          register={register('annotationField.longitude')}
        />
        <GetFieldInput
          label="Annotation metric field"
          description="The annotation field to use for metric"
          register={register('annotationField.metric')}
        />
      </div>
      <div style={useAnnotation ? { display: 'none' } : {}}>
        <GetFieldInput
          label="Data latitude field"
          description="The query field to use for latitude"
          register={register('field.latitude')}
        />
        <GetFieldInput
          label="Data longitude field"
          description="The query field to use for longitude"
          register={register('field.longitude')}
        />
        <GetFieldInput
          label="Data metric field"
          description="The query field to use for metric"
          register={register('field.metric')}
        />
      </div>
    </FieldSet>
  );
};

export const AnimationFields: React.FC<AnimationFieldsProps> = ({ register, watch }) => {
  const animate = watch('animate');
  return (
    <FieldSet label="Animation settings">
      <GetFieldSwitch
        label="Animate"
        description="Activate animation and set a duration"
        register={register('animate')}
      />
      {animate && (
        <GetFieldInput
          label="Animation duration"
          description="Lenght of an animation loop"
          register={register('animationDuration')}
        />
      )}
    </FieldSet>
  );
};

export const GeoJsonFields: React.FC<GeoJsonFieldsProps> = ({ register }) => (
  <FieldSet label="Geo drawing settings">
    <GetFieldSwitch label="Show border" description="Show the border of areas" register={register('showStroke')} />
    <GetFieldSwitch label="Show fill" description="Show the fill of areas" register={register('showFill')} />
  </FieldSet>
);

export const ColorFields: React.FC<ColorFieldsProps> = ({ register, errors }) => {
  return (
    <FieldSet label="Color settings">
      <GetFieldInput
        label="Transparency"
        description="Use as transparency for this layer [0-1]"
        register={register('transparency', {
          min: 0,
          max: 1,
          valueAsNumber: true,
          validate: (e) => e === undefined || (e >= 0 && e <= 1),
        })}
        type={'number'}
        invalid={errors.transparency !== undefined}
      />
    </FieldSet>
  );
};
