import { IconLayer as Layer } from '@deck.gl/layers/typed';
import { DataFrame, getFieldColorModeForField, Labels } from '@grafana/data';
import { iconMapping } from 'const';
import events from 'img/events.svg';
import tinycolor from 'tinycolor2';
import { IconLayer, LayerData, LayerType } from 'types';
import { getSeries } from 'utils';

interface IconData extends LayerData {
  position: [number, number];
  bearing: number;
  iconType: string;
}

export function getIconLayer(options: IconLayer, data: DataFrame[], onHover: (object: any) => void) {
  let dataField = options.useAnnotation ? options.annotationField : options.field;
  dataField = dataField || options.field || options.annotationField;
  if (!dataField) {
    return undefined;
  } else {
    const serie = getSeries(data, options.queryId);
    const _data: IconData[] = [];
    const labels = serie?.fields
      .map((e) => e.labels)
      .reduce((acc, cur) => {
        if (cur) {
          let exists = false;
          const keys = Object.keys(cur);
          acc.forEach((e) => {
            let eq = true;
            keys.forEach((key) => {
              if (!e[key]) {
                eq = false;
              } else if (e[key] !== cur[key]) {
                eq = false;
              }
            });
            if (eq) {
              exists = true;
            }
          });
          if (!exists) {
            acc.push(cur);
          }
        }
        return acc;
      }, [] as Labels[]);

    labels.forEach((label, index) => {
      const fields = serie?.fields.filter(
        (e) => e.labels?.[dataField?.metric || 'metric'] === label[dataField?.metric || 'metric'] || e.type === 'time'
      );
      const latField = fields.find((e) => e.name === dataField?.latitude || e.name === 'latitude');
      const longField = fields.find((e) => e.name === dataField?.longitude || e.name === 'longitude');

      // Find last index of the serie that has a value
      let i = serie.length - 1;
      for (i; i >= 0; i--) {
        if (latField?.values.get(i) && longField?.values.get(i)) {
          break;
        }
      }
      const position = [latField?.values.get(i), longField?.values.get(i)];
      const bearing = fields.find((e) => e.name === '__bearing')?.values.get(i) || 0;
      const field = fields.find((f) => f.name === dataField?.metric || f.name === 'metric') || fields[0];
      const mode = getFieldColorModeForField(field);
      let displayColor = field?.display?.('').color;
      if (mode.id === 'palette-classic') {
        displayColor = (mode as any).colorCache?.[index];
      }

      _data.push({
        fields: fields,
        position: position as [number, number],
        color: displayColor || '#005da8',
        iconType: options.icon || 'vessel',
        bearing: bearing,
      });
    });

    return new Layer<IconData, { type: LayerType }>({
      id: options.name,
      type: 'Icon',
      data: _data,
      parameters: {
        depthTest: false,
      },
      iconAtlas: options.iconAtlas || events,
      iconMapping: iconMapping,
      sizeMinPixels: options.iconSize || 35,
      sizeMaxPixels: options.iconSize || 35,
      pickable: true,
      visible: true,
      billboard: !!options.showBillboard,
      getPosition: (d) => d.position,
      getIcon: (d) => d.iconType,
      getColor: (d) => {
        const tColor = tinycolor(d.color || '#005da8').toRgb();
        return [tColor.r, tColor.g, tColor.b, tColor.a * 255];
      },
      getAngle: (d) => d.bearing,
      onHover: (data) => onHover({ ...data, dataIndex: serie.length - 1 }),
      updateTriggers: {
        getPosition: _data,
      },
    });
  }
}
