import React from 'react';

import {
  Field,
  formattedValueToString,
  getFieldDisplayName,
  GrafanaTheme2,
  textUtil,
  Vector,
} from '@grafana/data';
import { useStyles2, VizTooltipContainer, HorizontalGroup, SeriesTable } from '@grafana/ui';
import { css } from '@emotion/css';
import { Tag } from 'components/Tag/Tag';
import { LayerType } from 'types';
import { PickingInfo } from '@deck.gl/core/typed';
export const getEventType = (e: string) => {
  switch (e) {
    case 'BunkerManualEvent':
      return 'Bunker';
    case 'BunkerMeasuredEvent':
      return 'Bunker';
    case 'FuelChangeEvent':
      return 'Fuel change';
    case 'LeftBerthEvent':
      return 'Left berth';
    case 'ArrivedAtBerthEvent':
      return 'Arrived at berth';
    case 'CargoOperationEvent':
      return 'Cargo operation';
    case 'ReportEvent':
      return 'ROB';
    case 'SeaPassageInterruptedEvent':
      return 'Sea passage interrupted';
    case 'SeaPassageRecommencedEvent':
      return 'Sea passage recommenced';
    case 'ModeChangeEvent':
      return 'Mode change';
    case 'CorrectionEvent':
      return 'Correction';
    case 'FuelRegistrationEvent':
      return 'Fuel registration';
    default:
      return e;
  }
};

export const getEventText = (e: any, timeField: Field<any, Vector<any>>) => {
  let time = '<br>Time: -';
  if (e.start_time) {
    time = `<br>Time: ${timeField.display?.(e.start_time).text}`;
  }

  switch (e.type) {
    case 'LeftBerthEvent':
      return `<b>Left berth</b><br>
      ${time}
  <br>Port: ${e.port_name ?? '-'}
  <br>Locode: ${e.port_locode_part ?? '-'}`;
    case 'ArrivedAtBerthEvent':
      return `<b>Arrived at berth</b><br>
      ${time}
      <br>Port: ${e.port_name ?? '-'}
  <br>Locode: ${e.port_locode_part ?? '-'}`;
    case 'BunkerManualEvent':
    case 'BunkerMeasuredEvent':
      return `<b>Bunker</b><br>
      ${time}
  <br>Port: ${e.port_name ?? '-'}
  <br>Stream: ${e.bunker_stream_name ?? '-'}
  <br>Product: ${e.product_name ?? '-'}
  <br>${e.bunker_stream_direction === 'Supplier' ? 'Receiver' : 'Supplier'}: ${e.bunker_supplier ?? '-'}
  <br>Amount: ${
    Math.floor(e.bunker_total_mass) ? Math.floor(e.bunker_total_mass).toLocaleString() + ` ${e.mass_unit}` : '-'
  }`;
    case 'FuelChangeEvent':
      return `<b>Fuel change</b><br>
      ${time}
  <br>Fuel: ${e.fuel_name ?? '-'}
  <br>Consumer: ${e.consumer_name ?? '-'}`;
    case 'CargoOperationEvent':
    case 'ReportEvent':
    case 'SeaPassageInterruptedEvent':
    case 'SeaPassageRecommencedEvent':
    case 'ModeChangeEvent':
    case 'CorrectionEvent':
    case 'FuelRegistrationEvent':
      return `${getEventType(e.type)}
      ${time}
      <br>Comment: <br>${e.comment}`;
    default:
      return e.type;
  }
};

interface Props extends PickingInfo {
  type: LayerType;
  time?: number;
  dataIndex?: number;
  width: number;
  height: number;
}

export const HoverTooltip = (props: Props) => {
  const { x, y, width, height } = props;
  const style = useStyles2(getAnnotationMarkerStyles);
  const xlim = width - 130;
  const ylim = height - 40;
  return (
    <VizTooltipContainer
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        left: x < xlim ? x : undefined,
        right: x < xlim ? undefined : width - x,
        top: y < ylim ? y : undefined,
        bottom: y < ylim ? undefined : height - y,
      }}
      position={{ x, y }}
      offset={{ x: 0, y: 0 }}
      className={style.tooltip}
    >
      <InnerTooltip {...props} />
    </VizTooltipContainer>
  );
};

const InnerTooltip = (props: Props) => {
  const { type, object, dataIndex: index } = props;
  const style = useStyles2(getAnnotationMarkerStyles);
  switch (type) {
    case 'Event':
      const event = object.current;
      return (
        <div className={style.wrapper}>
          <div className={style.header}>{event.vessel_name && <span>{event.vessel_name}</span>}</div>
          <div
            className={style.body}
            dangerouslySetInnerHTML={{
              __html: textUtil.sanitize(
                getEventText(
                  event,
                  object.fields.find((e: Field<any, Vector<any>>) => e.type === 'time')
                )
              ),
            }}
          />
        </div>
      );
    case 'Icon':
    case 'Path':
      if (index !== undefined) {
        const fields = object?.fields as Array<Field<any, Vector<any>>>;
        const timeField = fields?.find((e) => e.type === 'time');
        const color = object?.color;
        return (
          <>
            {timeField && (
              <SeriesTable
                series={fields
                  .filter((f) => f.type !== 'time' && !f.name.startsWith('__'))
                  .map((f) => ({
                    color: color || f.display?.(f.values.get(index)).color || '#005da8',
                    label: (f.labels?.['metric'] ? f.labels?.['metric'] + ' - ' : '') + getFieldDisplayName(f),
                    value:
                      formattedValueToString(f.display!(f.values.get(index))) +
                      (f.labels?.['unit'] ? ` [${f.labels?.['unit'].replace('[', '').replace(']', '')}]` : ''),
                  }))}
                timestamp={formattedValueToString(timeField.display!(timeField.values.get(index)))}
              />
            )}
          </>
        );
      } else {
        return null;
      }
    default:
      return (
        <>
          <div className={style.wrapper}>
            <div className={style.header}>
              {object.properties?.area || null}
              {object.time && <span className={style.time}>{object.time}</span>}
            </div>
            <div className={style.body}>
              {object.text && <div dangerouslySetInnerHTML={{ __html: textUtil.sanitize(object.text) }} />}
              {object.properties?.regulation && (
                <div dangerouslySetInnerHTML={{ __html: textUtil.sanitize(object.properties?.regulation) }} />
              )}
              {object.tags && (
                <>
                  <HorizontalGroup spacing="xs" wrap>
                    {object.tags?.map((t: any, i: number) => (
                      <Tag name={t} key={`${t}-${i}`} color={object.color} />
                    ))}
                  </HorizontalGroup>
                </>
              )}
            </div>
          </div>
        </>
      );
  }
};

const getAnnotationMarkerStyles = (theme: GrafanaTheme2) => {
  return {
    markerWrapper: css`
      padding: 0 4px 4px 4px;
    `,
    wrapper: css`
      max-width: 400px;
    `,
    tooltip: css`
      padding: 0;
    `,
    header: css`
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.bodySmall.fontSize};
      display: flex;
    `,
    alertState: css`
      padding-right: ${theme.spacing(1)};
      font-weight: ${theme.typography.fontWeightMedium};
    `,
    time: css`
      color: ${theme.colors.text.secondary};
      font-style: italic;
      font-weight: normal;
      display: inline-block;
      position: relative;
      top: 1px;
    `,
    body: css`
      padding: ${theme.spacing(1)};
    `,
  };
};
