import React from 'react';
import { FieldSet } from '@grafana/ui';
import { DataFields, LayerEditorProps } from './common';
import { BarLayer } from 'types';
import { GetFieldInput } from '../GetField';

interface Props extends LayerEditorProps<BarLayer> {}

export const BarEditor: React.FC<Props> = (api) => {
  const { register } = api;
  return (
    <>
      <DataFields {...api} />
      <FieldSet label="Bar settings">
        <GetFieldInput
          label="Value field"
          description="Field to use for bar height"
          register={register('valueField')}
        />
        <GetFieldInput label="Bar radius" description="Radius of bar, in meters" register={register('radius')} />
        <GetFieldInput
          label="Height multiplier"
          description="Height of bar in meters = value x multiplier"
          register={register('multiplier')}
        />
      </FieldSet>
    </>
  );
};
