// @ts-ignore
import { ColumnLayer as Layer } from '@deck.gl/layers';
import { DataFrame } from '@grafana/data';
import tinycolor from 'tinycolor2';
import { BarLayer } from 'types';
import { getSeries } from 'utils';

export function getBarLayer(options: BarLayer, data: DataFrame[], onHover: (object: any) => void) {
  let dataField = options.useAnnotation ? options.annotationField : options.field;
  dataField = dataField || options.field || options.annotationField;
  if (!dataField) {
    return undefined;
  } else {
    const serie = getSeries(data, options.queryId);
    if (!serie) {
      return undefined;
    } else {
      const metricField = dataField.metric || 'metric';
      const tags = [...new Set(serie?.fields.map((e) => e.labels?.[metricField]))];

      const _data = [];
      for (let idx = 0; idx < tags.length; idx++) {
        const tag = tags[idx];
        if (!tag) {
          continue;
        }
        const fields = serie.fields.filter((e) => e.labels?.[metricField] === tag);
        for (let idx = 0; idx < serie.length; idx++) {
          const value = fields.find((e) => e.name === 'value')?.values.get(idx);
          const display = fields.find((e) => e.name === 'value')?.display?.(
            fields.find((e) => e.name === 'value')?.values.get(idx)
          );
          _data.push({
            latitude: fields.find((e) => e.name === dataField?.latitude)?.values.get(idx),
            longitude: fields.find((e) => e.name === dataField?.longitude)?.values.get(idx),
            bearing: fields.find((e) => e.name === '__bearing')?.values.get(idx),
            value,
            color: fields.find((e) => e.name === '__color')?.values.get(idx),
            _color: display?.color,
            iconType: fields.find((e) => e.name === '__iconType')?.values.get(idx),
            size: fields.find((e) => e.name === '__size')?.values.get(idx),
            text: display?.text,
            tags: [tag],
          });
        }
      }

      return new Layer({
        id: options.name,
        data: _data,
        parameters: {
          depthTest: false,
        },
        visible: true,
        pickable: true,
        // radius: options.radius || 1000,
        getPosition: (d: any) => [d.latitude, d.longitude],
        getElevation: (d: any) => d.value * (options.multiplier || 100000),
        getFillColor: (d: any) => {
          const color = d.color || d._color;
          const tColor = tinycolor(color || '#005da8').toRgb();
          return [tColor.r, tColor.g, tColor.b, tColor.a * 255 * d.value];
        },
        onHover,
        transitions: {
          getElevation: {
            type: 'interpolation',
            duration: 700,
            enter: () => [0],
          },
        },
      });
    }
  }
}
