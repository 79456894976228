import { PanelPlugin } from '@grafana/data';
import { LayersEditor } from './components';
import Deck from './Deck';
import { layerTypes } from './components/LayersEditor';
import { IconLayer, PathLayer, EcaLayer, DeckComponentOptions } from 'types';

const defaultIconLayer: IconLayer = {
  name: 'Vessel icon',
  field: {
    latitude: 'position_x',
    longitude: 'position_y',
  },
  aggregation: 'last',
  iconSize: 35,
  type: layerTypes.find((e) => e.value === 'Icon'),
  showAngle: true,
  showBillboard: false,
};

const defaultPathLayer: PathLayer = {
  name: 'Vessel trail',
  field: {
    latitude: 'position_x',
    longitude: 'position_y',
  },
  type: layerTypes.find((e) => e.value === 'Path'),
  width: 3,
};

const defaultEcaLayer: EcaLayer = {
  name: 'Standard ECA',
  showFill: true,
  showStroke: true,
  type: layerTypes.find((e) => e.value === 'ECA'),
};

const defaultLayers = [defaultPathLayer, defaultIconLayer, defaultEcaLayer];

// ts-prune-ignore-next
export const plugin = new PanelPlugin<DeckComponentOptions>(Deck)
  .setDataSupport({ annotations: true })
  .useFieldConfig()
  .setPanelOptions((builder) => {
    return builder
      .addTextInput({
        path: 'fitToBoundsLatitude',
        name: 'Latitude field to use for initial fitting',
        defaultValue: 'position_x',
        description: 'Sets the field to search for inital latitude',
      })
      .addTextInput({
        path: 'fitToBoundsLongitude',
        name: 'Longitude field to use for initial fitting',
        defaultValue: 'position_y',
        description: 'Sets the field to search for inital longitude',
      })
      .addNumberInput({
        path: 'fitToBoundsMaxZoom',
        name: 'Maximum zoom level for zoom to bounds',
        defaultValue: 6.5,
        description: 'When zooming to bounds, the map will not zoom closer than this setting',
      })
      .addBooleanSwitch({
        path: 'showMap',
        name: 'Show map',
        defaultValue: true,
        description: 'Show a map as base layer',
      })
      .addCustomEditor({
        name: 'Layers',
        description: 'A list of layers to apply, in order',
        defaultValue: defaultLayers,
        id: 'layers',
        path: 'layers',
        editor: LayersEditor,
      })
      .addBooleanSwitch({
        path: 'updateVariables.zoomlevel',
        category: ['Variables'],
        name: 'Sync zoomlevel',
        defaultValue: false,
        description: 'Variable named zoomlevel will be updated on map interaction',
      })
      .addBooleanSwitch({
        path: 'updateVariables.latitude',
        category: ['Variables'],
        name: 'Sync latitude',
        defaultValue: false,
        description: 'Variable named latitude will be updated on map interaction. This is the center of the map',
      })
      .addBooleanSwitch({
        path: 'updateVariables.longitude',
        category: ['Variables'],
        name: 'Sync longitude',
        defaultValue: false,
        description: 'Variable named longitude will be updated on map interaction. This is the center of the map',
      })
      .addBooleanSwitch({
        path: 'updateVariables.pitch',
        category: ['Variables'],
        name: 'Sync pitch',
        defaultValue: false,
        description: 'Variable named pitch will be updated on map interaction',
      })
      .addBooleanSwitch({
        path: 'updateVariables.bearing',
        category: ['Variables'],
        name: 'Sync bearing',
        defaultValue: false,
        description: 'Variable named bearing will be updated on map interaction',
      })
      .addBooleanSwitch({
        path: 'updateVariables.bbox',
        category: ['Variables'],
        name: 'Sync bounding box',
        defaultValue: false,
        description:
          'Variables named nw and se will be updated on map interaction. These are multivalue variables, representing the north-west and south-east corner of the visible area',
      });
  });
